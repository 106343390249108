import React, { useState} from "react"
import "./hundredMenu.css"

import { BigNumber } from "../../bigNumber"
import { useGlobalContext } from "../../Types/gloabalContext"



const HundredMenu: React.FC = () => {
    const {hndPrice, network} = useGlobalContext()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tvl] = useState<BigNumber | null>(null)

    // useEffect(() => {
    //     if(network  && network.hundredLiquidityPoolAddress && hundredBalance){
    //         if(network.liquidity){
    //             const temp = +hundredBalance.toString() / (network.hndPoolPercent ? network.hndPoolPercent : 1) * hndPrice
    //             setTvl(BigNumber.parseValue(temp.noExponents()))
    //         }
    //         else{
    //             const temp = +hundredBalance.toString() * 2 * hndPrice
    //             setTvl(BigNumber.parseValue(temp.noExponents()))
    //         }
    //     }
    //     else setTvl(null)
    // }, [hndPrice, hundredBalance, network])

    return (
        <div className="hundred-menu">
            <hr/>
            <div className="hundred-menu-item">
                <div className="hundred-menu-item-label"><label>HND Price </label><span>${BigNumber.parseValue(hndPrice.toString()).toRound(2, true, true)}</span></div>
                {tvl ? <div className="hundred-menu-item-label"><label>{network?.liquidity ? "Liquidity" : "TVL"}</label><span>${tvl.toRound(2, true, true)}</span></div> : null}
                {network  && network.trade ? <div className="hundred-menu-item-label"><a className="hundred-menu-link" href={network.trade} target="_blank" rel="noreferrer">Trade</a></div> : null}
                {network  && network.addLiquidity ? <div className="hundred-menu-item-label"><a className="hundred-menu-link" href={network.addLiquidity} target="_blank" rel="noreferrer">Add Liquidity</a></div> : null}
                {network  && network.stakeLp ? <div className="hundred-menu-item-label"><a className="hundred-menu-link" href={network.stakeLp} target="_blank" rel="noreferrer">Stake LP</a></div> : null}
            </div>
            {/* <div className="hundred-menu-item">
                <hr/>
                <div className="hundred-menu-item-label"><label>HND Balance </label><span>{hndBalance ? (hndBalance.gt(BigNumber.from(0)) ? hndBalance.toRound(2, true, true) : "0.00") : "--"}</span></div>
                {hndEarned && +hndEarned.toString() > 0 ? 
                    <><div className="hundred-menu-item-label"><label>HND Earned (Legacy)</label><span>{hndEarned ? hndEarned?.gt(BigNumber.from(0)) ? hndEarned?.toRound(2, true, true) : "0.00" : "--"}</span></div>
                    <div className={`${hndSpinner ? "hundred-menu-item-button-disabled" : "hundred-menu-item-button"}`} onClick={() => !hndSpinner ? handleCollect() : null}>
                        {hndSpinner ? (<Spinner size={"25px"}/>) : "Claim Legacy HND"}</div></> : null
                }
            </div> */}
        </div>
    )
}

export default HundredMenu