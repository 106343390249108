import React from "react"
import {HuLogo} from "../../assets/huIcons/huIcons"
import { useGlobalContext } from "../../Types/gloabalContext"
import "./navbar.css"

const NavbarLogo: React.FC = () => {
    const {isMobile, weveSkin, darkMode} = useGlobalContext()
    return (
        <div className="navbar-logo">
            <div className="navbar-logo-icon">
                <HuLogo size={isMobile ? "60px" : "80px"} darkMode={weveSkin && !darkMode ? false : true}/>
            </div>
            <span>Lendly</span>
             {/*<div className="navbar-logo-caption" style={{color: `${props.theme.text}`}}>
                <span style={{fontWeight:'bolder', userSelect:'none'}}>HUN</span><span style={{fontWeight:'leighter', userSelect:'none'}}>DRED</span>
                </div>*/}
        </div>
    )
}

export default NavbarLogo