import { createContext, useContext } from "react";
import { Network } from "../networks";
import { lightTheme, Theme } from "../theme";

export type GlobalContext = {
    address: string,
    setAddress: (a: string) => void,
    network: Network | null,
    setNetwork: (n: Network | null) => void,
    spinnerVisible: boolean,
    setSpinnerVisible: (n: boolean) => void,
    hndPrice: number, 
    setHndPrice: (p: number) => void
    hasClaimed: boolean,
    setHasClaimed: (c: boolean) => void,
    sideMenu: boolean,
    setSideMenu: (m: boolean) => void,
    darkMode: boolean,
    setDarkMode: (d: boolean) => void,
    isMobile: boolean,
    setIsMobile: (m: boolean) => void,
    isTablet: boolean,
    setIsTablet: (t: boolean) => void
    show: boolean,
    setShow: (s: boolean) => void
    theme: Theme,
    setTheme: (t: Theme) => void,
    openAddress: boolean,
    setOpenAddress: (t: boolean) => void,
    openNetwork: boolean,
    setOpenNetwork: (t: boolean) => void,
    openHundred: boolean,
    setOpenHundred: (t: boolean) => void,
    updatePrice: number | null,
    setUpdatePrice: (t: number | null) => void,
    myErrorHandler: (error: Error, info: {componentStack: string}) => void,
    toastError: (error: string, autoClose?: boolean, closeDelay?: number) => void,
    toastSuccess: (message: string, autoClose?: boolean, closeDelay?: number) => void,
    menuOpen: boolean,
    setMenuOpen: (o: boolean) => void
    weveSkin: boolean,
    setWeveSkin: (w: boolean) => void,
    showApp: boolean,
    setShowApp: (a: boolean) => void,
    switchModal: boolean,
    setSwitchModal: (m: boolean) => void,
    connect: boolean,
    setConnect: (m: boolean) => void,
}

export const MyGlobalContext = createContext<GlobalContext>({
    address: "",
    setAddress: () => {},
    network: null,
    setNetwork: () => {},
    spinnerVisible: false,
    setSpinnerVisible: () => {},
    hndPrice: 0,
    setHndPrice: () => {},
    hasClaimed: false,
    setHasClaimed: () => {},
    sideMenu: false,
    setSideMenu: () => {},
    darkMode: false,
    setDarkMode: () => {},
    isMobile: false,
    setIsMobile: () => {},
    isTablet: false,
    setIsTablet: () => {},
    show: false,
    setShow: () => {},
    theme: lightTheme,
    setTheme: () => {},
    openAddress: false,
    setOpenAddress: () => {},
    openNetwork: false,
    setOpenNetwork: () => {},
    openHundred: false,
    setOpenHundred: () => {},
    updatePrice: null,
    setUpdatePrice: () => {},
    myErrorHandler: () => {},
    toastError: () => {},
    toastSuccess: () => {},
    menuOpen: false,
    setMenuOpen: () => {},
    weveSkin: false,
    setWeveSkin: () => {},
    showApp: false,
    setShowApp: () => {},
    switchModal: false,
    setSwitchModal: () => undefined,
    connect: false,
    setConnect: () => undefined
})

export const useGlobalContext = () => useContext(MyGlobalContext)
