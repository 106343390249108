import { useEffect, useState } from "react"
import {createPortal}  from "react-dom"
import { HuLogo } from "../../assets/huIcons/huIcons"
import { useGlobalContext } from "../../Types/gloabalContext"
import "./overlay.css" 

const overlayRoot = document.getElementById("overlay") as HTMLElement

const OverLay = () => {
    const [showOverlay, setShowOverlay] = useState(true)
    const [size, setSize] = useState(0)
    const {showApp, theme} = useGlobalContext()


    useEffect(() => {
        const handleWindowSize = () => {
            setSize( window.innerWidth > 1024 ? 1024 / 2 : window.innerWidth / 2)
        }

        handleWindowSize()
        window.addEventListener('resize', handleWindowSize)

        return(() => {
            window.removeEventListener("resize", handleWindowSize)
        })
    }, [])

    useEffect(() => {
        if(showApp && theme)
            setTimeout(() => setShowOverlay(false), 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showApp, theme])

    if(!showOverlay) return null

    return createPortal(
        <div className="overlay">
            <div className={`overlay-left ${theme && showApp ? "overlay-left-collapse" : ""}`}>
                <div className="logo" style={{right: size/2 * -1}}><HuLogo darkMode={true} size={size.toString()}/></div>
            </div>
            <div className={`overlay-right ${theme && showApp ? "overlay-right-collapse" : ""}`}>
                <div className="logo" style={{left: size/2 * -1}}><HuLogo darkMode={true} size={size.toString()}/></div>
            </div>
        </div> , overlayRoot
    )
}

export default OverLay