import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Footer from '../Components/Footer/footer'
import Menu from '../Components/Menu/menu'
import TabletMenu from '../Components/Menu/tabletMenu'
import AccountSettings from '../Components/SideMenu/accountSettings'
import HundredMenu from '../Components/SideMenu/hundredMenu'
import NetworksView from '../Components/SideMenu/networksView'
import SideMenu from '../Components/SideMenu/sideMenu'
import Wrapper from '../Components/Wrapper/wrapper'
import { useGlobalContext } from '../Types/gloabalContext'
import ReactToolTip from 'react-tooltip'
import Spinner from '../Components/Spinner/spinner'


export const Layout:React.FC = () => {
    const {isTablet, isMobile, openAddress, openNetwork, openHundred} = useGlobalContext()
    return (
        <>
            <Wrapper>
                {!isTablet && !isMobile ? 
                    <Menu/> : <TabletMenu/>}
                <Outlet/>
                <ToastContainer/>
                </Wrapper>
            <Footer/>
            <SideMenu>
                { openAddress ? <AccountSettings/> 
                : (openNetwork ? <NetworksView/> 
                : openHundred ? <HundredMenu /> 
                : null)
                }
            </SideMenu>
            <ReactToolTip id="tooltip" effect="solid"/>
            <Spinner/>
        </>
      )
}
