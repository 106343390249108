import React from "react"
import { useGlobalContext } from "../Types/gloabalContext"
import FantomMain from "./Fantom/fantomMain"
import "./main.css"

const Main: React.FC = () => {
    const { network, setConnect } = useGlobalContext()

    if(network)
    {
        if(network.chainId === 250)
            return (
                <FantomMain/>
            )
    }

    return(
        <div className="main">
            <h1>Welcome to Hundred Lendly</h1>
            <h2>Please <span className="switch-network-link" onClick={() => setConnect(true)}>Connect</span> to access the App</h2>
        </div>
    )
}

export default Main