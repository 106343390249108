import { ethers } from "ethers";
import { useState } from "react";
import { Network } from "../networks";

export const useProvider = (initialValue: ethers.providers.Web3Provider | null = null) =>{
    const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(initialValue)

    const updateProvider = (value: ethers.providers.Web3Provider | null) => setProvider(value)

    return [provider, updateProvider] as const 
}

export const useAddress = (initialValue: string = "") =>{
    const [address, setAddress] = useState<string>(initialValue)

    const updateAddress = (value: string) => setAddress(value)

    return [address, updateAddress] as const 
}

export const useNetwork = (initialValue: Network | null = null) =>{
    const [network, setNetwork] = useState(initialValue)

    const updateNetwork = (value: Network | null) => setNetwork(value)

    return [network, updateNetwork] as const 
}