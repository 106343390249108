import React, { useState, useEffect} from 'react'
import {lightTheme, darkTheme, Theme} from './theme'
import './App.css';
import {Network} from './networks'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MyGlobalContext } from './Types/gloabalContext';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { Layout } from './Layout/layout';
import { useAddress, useNetwork} from './Types/customHooks';
import Main from './Pages/main';
import FantomHND from './Pages/Fantom/fantomHND';
import FantomWEVE from './Pages/Fantom/fantomWEVE';
import OverLay from './Components/Overlay/overlay';
import { useWeb3React } from '@web3-react/core';
import { GetConnector } from './Connectors/connectors';
import Buffer from "buffer"



declare global {
  interface Window {
    ethereum: any,
  }
}

global.Buffer = window.Buffer || Buffer.Buffer

const App: React.FC = () => {
  const { activate} = useWeb3React()

  const [showApp, setShowApp] = useState(false)
  const [address, setAddress] = useAddress()
  const [network, setNetwork] = useNetwork()

  const [hndPrice, setHndPrice] = useState<number>(0)
  const [hasClaimed, setHasClaimed] = useState<boolean>(false)

  const [sideMenu, setSideMenu] = useState<boolean>(false)
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const [spinnerVisible, setSpinnerVisible] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [theme, setTheme] = useState<Theme>(lightTheme)
  const [openAddress, setOpenAddress] = useState<boolean>(false)
  const [openNetwork, setOpenNetwork] = useState<boolean>(false)
  const [openHundred, setOpenHundred] = useState<boolean>(false)
  const [updatePrice, setUpdatePrice] = useState<number | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const [connect, setConnect] = useState(false)

  const [weveSkin, setWeveSkin] = useState(false)

  const [switchModal, setSwitchModal] = useState(false)

  const resizeWindow = () => {
    if (document.documentElement.clientWidth < (!hasClaimed ? 750 : 925)){
      setIsMobile(true)
      setIsTablet(false)
    }
    else if (document.documentElement.clientWidth < (!hasClaimed ? 1070 : 1150)){
  
      setIsTablet(true)
      setIsMobile(false)
    }
    else setIsTablet(false)
  }

  
  useEffect(() => {
   resizeWindow()

    setShow(true)

    window.addEventListener('resize', resizeWindow);
      
    const darkmode = window.localStorage.getItem("hundred-darkmode")
    const net = window.localStorage.getItem("hundred-network")
    const prov = window.localStorage.getItem("hundred-provider")
    
    let tempNet: Network | null = null



    if(darkmode && darkmode === "dark")
      setDarkMode(true)
    else
      setDarkMode(false)
    
    if(net)
      tempNet = JSON.parse(net) as Network

    if(prov) 
    {
      const con = GetConnector(+prov, tempNet ? tempNet.chainId : undefined)
      activate(con)
    }
    

    setSpinnerVisible(false)
    const hndPrice = getHndPrice()
    Promise.all([hndPrice])
    setShowApp(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(darkMode){
      window.localStorage.setItem("hundred-darkmode", "dark")
      setTheme(darkTheme)
    }
    else{
      window.localStorage.setItem("hundred-darkmode", "light")
      setTheme(lightTheme)
    }
  }, [darkMode])

  useEffect(() => {
    window.localStorage.setItem("hundred-network", JSON.stringify(network))
    
  }, [network])

  const getHndPrice = async () : Promise<void>  => {
    if(updatePrice) window.clearTimeout(updatePrice)
    try{
        const url =  "https://api.coingecko.com/api/v3/simple/price?ids=hundred-finance&vs_currencies=usd"
        const headers = {}
        const response = await fetch(url,
            {
                method: "GET",
                mode: 'cors',
                headers: headers
            }
          )
          const data = await response.json()
          const hnd = data ? data["hundred-finance"] : null
          const usd: number = hnd ? +hnd.usd : 0
    
          setHndPrice(usd)
    }
    catch(err){
        console.log(err)
    }
    finally{
      setUpdatePrice(window.setTimeout(getHndPrice, 60000))
    }
  }


  const myErrorHandler = (error: Error, info: {componentStack: string}) => {
    console.log(error)
    console.log(info)
    toast.error('An error has occurred, please check console log.', {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const toastError = (error: string, autoClose = true, closeDelay = 10000) => {
    toast.error(error, {
      position: "top-right",
      autoClose: autoClose ? closeDelay : false,
      hideProgressBar: autoClose ? false : true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: true
      });
  }

  const toastSuccess = (message: string, autoClose = true, closeDelay = 10000) => {
    toast.success(message, {
      position: "top-right",
      autoClose: autoClose ? closeDelay : false,
      hideProgressBar: autoClose ? false : true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: true
      });
  }

  return (
    <MyGlobalContext.Provider value={({
      address, setAddress, 
      network, setNetwork,
      spinnerVisible, setSpinnerVisible,
      hndPrice, setHndPrice,
      hasClaimed, setHasClaimed,
      sideMenu, setSideMenu,
      darkMode, setDarkMode,
      isMobile, setIsMobile,
      isTablet, setIsTablet,
      show, setShow,
      theme, setTheme,
      openAddress, setOpenAddress,
      openNetwork, setOpenNetwork,
      openHundred, setOpenHundred,
      updatePrice, setUpdatePrice,
      myErrorHandler, toastError, toastSuccess,
      menuOpen, setMenuOpen,
      weveSkin, setWeveSkin,
      showApp, setShowApp,
      switchModal, setSwitchModal,
      connect, setConnect
    })}>
        <div id="app" className={`App scroller ${darkMode ? "App-dark" : weveSkin ? "weve" : ""}`}>
          <HashRouter basename="/">
          <Routes>
              <Route path="/" element={<Layout/>}>
                <Route index element={<Main/>}/>
                {network && network.chainId === 250 ? 
                (<>
                  <Route path='hnd' element={<FantomHND/>}/>
                  <Route path='weve' element={<FantomWEVE/>}/>
                </>)
              : null}
              <Route path="*" element={<Main/>}/>
              </Route>
          </Routes>
          </HashRouter>
        </div>
        <OverLay/>
    </MyGlobalContext.Provider>
  )
}

export default App;
