import React from "react"
import "./themeSwitch.css"
import { Sun, Moon } from "../../assets/huIcons/huIcons";
import { useGlobalContext } from "../../Types/gloabalContext";

const ThemeSwitch: React.FC = () => {
    const {darkMode, setDarkMode, setMenuOpen} = useGlobalContext()
    const handleOpenMenu = () => {
        setDarkMode(!darkMode)
        setMenuOpen(false)
        
    }
    return (
        <div className={`theme-switch ${darkMode ? "theme-switch-dark-mode" : ""}`} onClick={() => handleOpenMenu()}>
            <div className={`switch-button ${darkMode ? "switch-button-dark-mode" : ""}`}></div>
            <Sun darkMode={darkMode} size="15px"/>
            <Moon darkMode={darkMode} size="15px"/>
        </div>
    )
}

export default ThemeSwitch