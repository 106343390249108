import React from 'react'
import Content from '../../Components/Content/content';
import {ErrorBoundary} from "react-error-boundary"
import 'react-toastify/dist/ReactToastify.css'
import { useGlobalContext } from '../../Types/gloabalContext';

const FantomHND: React.FC = () => {
  const {network, myErrorHandler,
            spinnerVisible, darkMode, hndPrice, toastError,
            setSpinnerVisible} = useGlobalContext()

  
  
  const errorFallback = () => {
    return(
      <ErrorBoundary fallbackRender={errorFallback} onError={myErrorHandler}>
        <Content  setSpinnerVisible={setSpinnerVisible} 
          spinnerVisible={spinnerVisible} darkMode={darkMode} hndPrice={hndPrice} toastError={toastError} lendly={network ? network.lendly["HND"] : null}/>
      </ErrorBoundary>
    )
  }

  return (
    
        <ErrorBoundary fallbackRender={errorFallback} onError={myErrorHandler}>
          <Content setSpinnerVisible={setSpinnerVisible} 
            spinnerVisible={spinnerVisible} darkMode={darkMode} hndPrice={hndPrice} toastError={toastError} lendly={network ? network.lendly["HND"] : null}/>
        </ErrorBoundary>
       
  )
}

export default FantomHND;
