import { Contract, Provider } from 'ethcall'
import {CTOKEN_ABI, GAUGE_CONTROLLER_ABI, GAUGE_V4_ABI, MINTER_ABI, REWARD_POLICY_MAKER_ABI, TOKEN_ABI} from '../abi'
import { LendlyData, Network } from '../networks'
import {BigNumber} from "../bigNumber";
import {ethers} from "ethers";
import _, {floor} from "lodash";

export interface GaugeV4GeneralData {
    address : string
    lpToken: string
    minter: string
    rewardPolicyMaker: string
    totalStake: BigNumber
    workingTotalStake: BigNumber
    weight: BigNumber
    veHndRewardRate: BigNumber
    reward_token: string
}

export class GaugeV4{
    generalData : GaugeV4GeneralData
    userStakeBalance: BigNumber
    userStakedTokenBalance: BigNumber
    userLpBalance: BigNumber
    userClaimableHnd: BigNumber
    userWorkingStakeBalance: BigNumber
    userAllowance: BigNumber
    reward_token: string
    reward_token_decimals: number
    reward_token_symbol: string
    claimable_reward: BigNumber
    token_yearly_rewards: BigNumber
    stakeCall: (amount: string) => void
    unstakeCall: (amount: string) => void
    mintCall: () => void
    claimRewardsCall: () => void
    approveCall: () => void

    constructor(
        generalData: GaugeV4GeneralData,
        userStakeBalance: BigNumber,
        userLpBalance: BigNumber,
        userClaimableHnd: BigNumber,
        userWorkingStakeBalance: BigNumber,
        userAllowance: BigNumber,
        reward_token: string,
        reward_token_decimals: number,
        reward_token_symbol: string,
        claimable_reward: BigNumber,
        token_yearly_rewards: BigNumber,
        stakeCall: (amount: string) => void,
        unstakeCall: (amount: string) => void,
        mintCall: () => void,
        claimRewardsCall: () => void,
        approveCall: () => void,
    ){
        this.generalData = generalData
        this.userStakeBalance = BigNumber.from(userStakeBalance.toString(), 18)
        this.userStakedTokenBalance = userStakeBalance
        this.userLpBalance = BigNumber.from(userLpBalance.toString(), 8)
        this.userClaimableHnd = BigNumber.from(userClaimableHnd.toString(), 18)
        this.userWorkingStakeBalance = userWorkingStakeBalance
        this.userAllowance = BigNumber.from(userAllowance.toString(), 8)
        this.reward_token = reward_token
        this.reward_token_symbol = reward_token_symbol
        this.reward_token_decimals = reward_token_decimals
        this.claimable_reward = BigNumber.from(claimable_reward.toString(), reward_token_decimals)
        this.token_yearly_rewards = BigNumber.from(token_yearly_rewards.toString(), reward_token_decimals).mul(BigNumber.from(3600 * 24 * 365))
        this.stakeCall = stakeCall
        this.unstakeCall = unstakeCall
        this.mintCall = mintCall
        this.claimRewardsCall = claimRewardsCall
        this.approveCall = approveCall
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getGaugesData = async (provider: any, userAddress: string, network: Network, lendly: LendlyData): Promise<Array<GaugeV4>> => {
    const ethcallProvider = new Provider()
    await ethcallProvider.init(provider)

    if(network.multicallAddress) {
        ethcallProvider.multicall={
            address: network.multicallAddress,
            block: 0
        }
    }

    let generalData: Array<GaugeV4GeneralData> = [];

    if (lendly.gaugeControllerAddress) {
        const controller = lendly.gaugeControllerAddress
        const ethcallGaugeController = new Contract(controller, GAUGE_CONTROLLER_ABI)

        const [nbGauges] = await ethcallProvider.all([ethcallGaugeController.n_gauges()]) as any

        const gauges: any[] = await ethcallProvider.all(Array.from(Array(nbGauges.toNumber()).keys()).map(i => ethcallGaugeController.gauges(i)))
        const activeGauges: string[] = [];

        let lpAndMinterAddresses: any[] = await ethcallProvider.all(
            gauges.flatMap((g) => [
                new Contract(g, GAUGE_V4_ABI).lp_token(),
                new Contract(g, GAUGE_V4_ABI).minter(),
                new Contract(g, GAUGE_V4_ABI).reward_policy_maker(),
                new Contract(g, GAUGE_V4_ABI).working_supply(),
                new Contract(g, GAUGE_V4_ABI).is_killed(),
                new Contract(controller, GAUGE_CONTROLLER_ABI).gauge_relative_weight(g),
                new Contract(g, GAUGE_V4_ABI).reward_tokens(0),
            ])
        )

        lpAndMinterAddresses = _.chunk(lpAndMinterAddresses, 7)
        const activeLpAndMinterAddresses: any[][] = []

        for (let i = 0; i < lpAndMinterAddresses.length; i++) {
            if (!lpAndMinterAddresses[i][4]) {
                activeGauges.push(gauges[i]);
                activeLpAndMinterAddresses.push(lpAndMinterAddresses[i])
            }
        }

        let rewards: any[] = await ethcallProvider.all(
            activeGauges.flatMap((g, index) => [
                    new Contract(activeLpAndMinterAddresses[index][2], REWARD_POLICY_MAKER_ABI).rate_at(floor(new Date().getTime() / 1000)),
                    new Contract(activeLpAndMinterAddresses[index][0], CTOKEN_ABI).balanceOf(g)
                ]
            )
        )

        rewards = _.chunk(rewards, 2)

        generalData = activeLpAndMinterAddresses.map((c, index) => {
            return {
                address: activeGauges[index],
                lpToken: c[0],
                minter: c[1],
                rewardPolicyMaker: c[2],
                weight: c[5],
                totalStake: rewards[index][1],
                workingTotalStake: c[3],
                veHndRewardRate: rewards[index][0],
                reward_token: c[6]
            }
        });

        if (generalData.length > 0) {

            const info = await ethcallProvider.all(
                generalData.flatMap((g) => [
                    new Contract(g.address, GAUGE_V4_ABI).balanceOf(userAddress),
                    new Contract(g.lpToken, CTOKEN_ABI).balanceOf(userAddress),
                    new Contract(g.address, GAUGE_V4_ABI).claimable_tokens(userAddress),
                    new Contract(g.address, GAUGE_V4_ABI).working_balances(userAddress),
                    new Contract(g.lpToken, CTOKEN_ABI).allowance(userAddress, g.address),
                    g.reward_token !== "0x0000000000000000000000000000000000000000" ? new Contract(g.reward_token, TOKEN_ABI).decimals() : new Contract(g.lpToken, TOKEN_ABI).decimals(),
                    g.reward_token !== "0x0000000000000000000000000000000000000000" ? new Contract(g.reward_token, TOKEN_ABI).symbol() : new Contract(g.lpToken, TOKEN_ABI).symbol(),
                    new Contract(g.address, GAUGE_V4_ABI).claimable_reward(userAddress, g.reward_token),
                    new Contract(g.address, GAUGE_V4_ABI).reward_data(g.reward_token),
                ])
            )

            const infoChunks: any[] = _.chunk(info, 9);

            let now = Math.trunc(new Date().getTime() / 1000);

            return generalData.map((g, index) => {
                return new GaugeV4(
                        g,
                        infoChunks[index][0],
                        infoChunks[index][1],
                        infoChunks[index][2],
                        infoChunks[index][3],
                        infoChunks[index][4],
                        g.reward_token,
                        infoChunks[index][5],
                        infoChunks[index][6],
                        infoChunks[index][7],
                        infoChunks[index][8].period_finish.toNumber() > now ? infoChunks[index][8].rate : 0,
                        (amount: string) => stake(provider, g.address, amount),
                        (amount: string) => unstake(provider, g.address, amount),
                        () => mint(provider, g.address),
                        () => claimRewards(provider, g.address),
                    () => approve(provider, g.address, g.lpToken)
                    )
                }
            )
        }
    }

    return []
  }

const MaxUint256 = BigNumber.from(ethers.constants.MaxUint256)

const stake = async (provider: any, gaugeAddress: string, amount: string) => {
    const signer = provider.getSigner()
    const gauge = new ethers.Contract(gaugeAddress, GAUGE_V4_ABI, signer)
    console.log("gauge", gauge, ethers.utils.parseUnits(amount, 8).toString())
    const tx = await gauge['deposit(uint256)'](ethers.utils.parseUnits(amount, 8))
    await tx.wait()
}

const unstake = async (provider: any, address: string, amount: string) => {
    const signer = provider.getSigner()
    const gauge = new ethers.Contract(address, GAUGE_V4_ABI, signer)
    const tx = await gauge['withdraw(uint256)'](ethers.utils.parseUnits(amount, 18))
    await tx.wait()
}

const mint = async (provider: any, address: string) => {
    const signer = provider.getSigner()
    const gauge = new ethers.Contract(address, GAUGE_V4_ABI, signer)

    const minterAddress = await gauge.minter()

    const minter = new ethers.Contract(minterAddress, MINTER_ABI, signer)

    const tx = await minter.mint(address)
    await tx.wait()
}

const claimRewards = async (provider: any, address: string) => {
    const signer = provider.getSigner()
    const gauge = new ethers.Contract(address, GAUGE_V4_ABI, signer)

    const tx = await gauge['claim_rewards()']()
    await tx.wait()
}

const approve = async (provider: any, gaugeAddress: string, lpTokenAddress: string) => {
    const signer = provider.getSigner()
    const contract = new ethers.Contract(lpTokenAddress, TOKEN_ABI, signer);
    const approveTx = await contract.approve(gaugeAddress, MaxUint256._value)
    await approveTx.wait();
}
