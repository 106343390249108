import React, { ReactNode } from "react"
import { useGlobalContext } from "../../Types/gloabalContext"
import "./navbar.css"

interface Props {
    children?: ReactNode
}

const Navbar: React.FC<Props> = (props: Props) => {
    const {isMobile, isTablet} = useGlobalContext()

    return (
            <div className={`navbar ${isTablet || isMobile ? "navbar-mobile" : ""}`}>
                <div className="navbar-content">
                    {props.children}
                </div>
            </div>
    )
}

export default Navbar