import Logos from "./logos"

export enum MasterChefVersion {
    v1,
    v2
}

type NativeCurrency = {
    name: string,
    decimals: number,
    symbol: string
}

type BackstopMasterChef = {
    address: string,
    version: MasterChefVersion,
    collaterals?: number
}

type NetworkParams = {
    chainId: string,
    rpcUrls: string[],
    chainName: string,
    nativeCurrency: NativeCurrency,
    blockExplorerUrls: string[]
}

type Network = {
    chainId: number,
    network: string,
    logo: string,
    name: string,
    blocksPerYear: number,
    networkParams: NetworkParams,
    addLiquidity?: string,
    trade?: string,
    stakeLp?: string,
    blockRpc?: string,
    compoundLensAddress?: string,
    hundredLiquidityPoolAddress?: string,
    hndPoolPercent?: number,
    liquidity?: boolean, 
    multicallAddress?: string,
    backstopMasterChef?: BackstopMasterChef,
    minterAddress?: string,
    airdropMulticallAddress?: string,
    lendly: Lendly
}

export type LendlyData = {
    nativeTokenMarketAddress: string,
    unitrollerAddress: string,
    hundredAddress: string,
    gaugeControllerAddress?: string,
}

type Lendly = {
    [key: string]: LendlyData
}

type NetworkData = {
    [key:number]: Network
}

const NETWORKS: NetworkData = {
   
    250: {
        chainId: 250,
        network: "Fantom Opera", 
        logo: Logos["FTM"],
        name: "FTM",
        blocksPerYear: 24*60*60*365,
        networkParams: {
            chainId: "0xfa",
            chainName: "Fantom Opera",
            rpcUrls: ["https://rpc.ftm.tools/"],
            nativeCurrency: {
                name: "FTM",
                decimals: 18,
                symbol: "FTM"
            },
            blockExplorerUrls: ["https://ftmscan.com"]
        },
        lendly: {
            "HND":{
                nativeTokenMarketAddress: "",
                unitrollerAddress: "0xeea62eD232fF4CCB6425d41AFB1b0B41d34F3114",
                hundredAddress: "0x10010078a54396f62c96df8532dc2b4847d47ed3",
                gaugeControllerAddress: "0x198618d2aa6cBC89Ea24550fE896D4afa28CD635",
            },
            "WEVE":{
                nativeTokenMarketAddress: "",
                unitrollerAddress: "0x612dcaaf5b20774f2ebbed49bc82442d642b7082",
                hundredAddress: "0x10010078a54396f62c96df8532dc2b4847d47ed3",
                gaugeControllerAddress: "0x198618d2aa6cBC89Ea24550fE896D4afa28CD635",
            }
        }
    }
} 

export default NETWORKS
export type { Network }
