import React from "react"
import { useGlobalContext } from "../../Types/gloabalContext"
import './navbarButton.css'

const NavBarButton: React.FC = () => {
    const {menuOpen, setMenuOpen} = useGlobalContext()
    const handleClick = () : void => {
        setMenuOpen(!menuOpen)
    }

    return (
        <div className={`navbar-button ${menuOpen ? "navbar-button-clicked" : ""}`} onClick={() => handleClick()}>
            <span className="bar bar1"></span>
            <span className="bar bar2"></span>
            <span className="bar bar3"></span>
        </div>
    )
}

export default NavBarButton