import React from "react"
import Logos from "../../logos"
import "./hundredButton.css"
import { useGlobalContext } from "../../Types/gloabalContext"

const HundredButton : React.FC = () => {
    const {setSideMenu, setOpenHundred, address, network} = useGlobalContext()

    const handleOpenHundred = () :void => {
            setOpenHundred(true)
            setSideMenu(true)
    }

    if(address === "" || !network)
        return null
    else {
        return (
            <div className="hundred-button" onClick={() => handleOpenHundred()}>
            {
                <div className="hundred-button-content">
                    <img src={Logos["HND"]} alt="" className="hundred-logo"/>
                    <span className="hundred-name">HND</span>
                    <span className="arrow">&#9660;</span>    
                </div>
            }
            </div>
        )
    }
}

export default HundredButton