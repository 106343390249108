import { HuLogo } from "../../assets/huIcons/huIcons"
import { NavLink } from "react-router-dom"
import "./fantomMain.css"
import VeDAOLogo from "../../assets/huIcons/veDAOLogo"

const FantomMain = () => {
    return(
        <div className="main-container">
            <NavLink to="hnd" className="lendly-item">
                <HuLogo size="150px"/>
            </NavLink>
            <NavLink to="weve" className="lendly-item">
                <VeDAOLogo size="200"/>
            </NavLink>
        </div>
    )
}

export default FantomMain