import React from "react"
import AddressButton from "../AddressButton/addressButton"
import Navbar from "../Navbar/navbar"
import NavBarButton from "../Navbar/navBarButton"
import NavbarLink from "../Navbar/navBarLink"
import NavBarLinks from "../Navbar/navBarLinks"
import NavbarLogo from "../Navbar/navbarLogo"
import NavbarMobile from "../Navbar/navbarMobile"
import NavBarRight from "../Navbar/navBarRight"
import ThemeSwitch from "../Navbar/themeSwitch"
import NetworkButton from "../NetworkButton/networkButton"
import HundredButton from "../HundredButton/hundredButton"
import { useGlobalContext } from "../../Types/gloabalContext"
import { NavLink } from "react-router-dom"

const TabletMenu: React.FC = () => {
    const {isTablet, isMobile, show} = useGlobalContext()

    return(
        ((isTablet || isMobile) && show) ? 
        (<>
            <Navbar>
                <NavLink style={{textDecoration: "none"}} to= "/">
                    <NavbarLogo/>
                </NavLink>
                    <NavBarRight>
                    {isTablet && !isMobile ?
                        <>
                            <HundredButton/>
                            <NetworkButton/> 
                            <AddressButton/>
                            <ThemeSwitch/>
                        </>
                    : null}
                        <NavBarButton/>
                    </NavBarRight>
            </Navbar>
            <NavbarMobile>
            {isMobile ? 
                <NavBarRight className="navbar-right-content">
                    <HundredButton/>
                    <NetworkButton/> 
                    <AddressButton/>
                    <ThemeSwitch/>
                </NavBarRight>
            : null}
            <NavBarLinks>
                <NavbarLink link="https://hundred.finance" target="_blank">Hundred</NavbarLink>
                <NavbarLink link="https://lendly.dashboard.hundred.finance" target="_blank">Dashboard</NavbarLink>
                <NavbarLink link="https://docs.hundred.finance/collaborations/lendly" target="_blank">Docs</NavbarLink>
                <NavbarLink link="https://vote.hundred.finance" target="_blank">Vote</NavbarLink>
             </NavBarLinks>
        </NavbarMobile>
        </>
        )
        : null
    )
} 

export default TabletMenu
