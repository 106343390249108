import React from "react"
import { useGlobalContext } from "../../Types/gloabalContext"
import "./networkButton.css"

const NetworkButton : React.FC = () => {
    const {network, setOpenNetwork, setSideMenu} = useGlobalContext()
    
    const handleOpenNetworks = () :void => {    
        setOpenNetwork(true)
        setSideMenu(true)
    }

    if(network){
        return (
            <div className="network-button" onClick={() => handleOpenNetworks()}>
            {
                <div className="network-button-content">
                    <img src={network.logo} alt="" className="network-logo"/>
                    <span className="network-name">{network.network}</span>
                    <span className="arrow">&#9660;</span>    
                </div>
            }
            </div>
        )
    }
    else return (
        <div className="network-button" onClick={() => handleOpenNetworks()}>
            <div className="network-button-content">
                <span className="network-name">Networks</span>
                <span className="arrow">&#9660;</span>    
            </div>
        </div>
    )
}

export default NetworkButton