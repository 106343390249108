import React, { ReactNode } from "react"
import { useGlobalContext } from "../../Types/gloabalContext"
import "./navbar.css"

interface Props {
    children?: ReactNode
}

const NavbarMobile: React.FC<Props> = ({children}: Props) => {
    const {menuOpen} = useGlobalContext()
    return (
        <div className={`navbar-mobile-content ${menuOpen ? "navbar-mobile-content-open" : ""}`}>
            {children}
        </div>
    )
}

export default NavbarMobile