import React from "react"
import HundredButton from "../HundredButton/hundredButton"
import AddressButton from "../AddressButton/addressButton"
import Navbar from "../Navbar/navbar"
import NavbarLeft from '../Navbar/navBarLeft'
import NavbarLink from "../Navbar/navBarLink"
import NavBarLinks from "../Navbar/navBarLinks"
import NavbarLogo from "../Navbar/navbarLogo"
import NavBarRight from "../Navbar/navBarRight"
import ThemeSwitch from "../Navbar/themeSwitch"
import NetworkButton from "../NetworkButton/networkButton"
import { useGlobalContext } from "../../Types/gloabalContext"
import { NavLink } from "react-router-dom"

const Menu : React.FC = () => {
    const {isTablet, isMobile, show} = useGlobalContext()
    return(
        (!isTablet && !isMobile && show) ? (
            <Navbar>
                <NavbarLeft>
                  <NavLink style={{textDecoration: "none"}} to="/">
                    <NavbarLogo/>
                  </NavLink>
                  <NavBarLinks>
                    <NavbarLink link="https://hundred.finance" target="_blank">Hundred</NavbarLink>
                    <NavbarLink link="https://lendly.dashboard.hundred.finance" target="_blank">Dashboard</NavbarLink>
                    <NavbarLink link="https://docs.hundred.finance/collaborations/lendly" target="_blank">Docs</NavbarLink>
                    <NavbarLink link="https://vote.hundred.finance" target="_blank">Vote</NavbarLink>
                  </NavBarLinks>
                </NavbarLeft>
                <NavBarRight>
                  <HundredButton/>
                  <NetworkButton/>
                  <AddressButton/>
                  <ThemeSwitch/>
                  {/* <SideMenuButton theme={theme} setSideMenu ={setSideMenu}/> */}
                </NavBarRight>
            </Navbar>
        ) : null
    )
} 

export default Menu