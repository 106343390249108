import React, { useEffect } from 'react'
import Content from '../../Components/Content/content';
import {ErrorBoundary} from "react-error-boundary"
import 'react-toastify/dist/ReactToastify.css'
import { useGlobalContext } from '../../Types/gloabalContext';
import "./fantomWEVE.css"

const FantomWEVE: React.FC = () => {
    const {network, myErrorHandler,
            spinnerVisible, darkMode, hndPrice, toastError,
            setSpinnerVisible, setWeveSkin} = useGlobalContext()
      // const [showNetworkError, ] = useState(true)

    useEffect(() => {
        setWeveSkin(true)
       
        return(() => {
            setWeveSkin(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })

  
  const errorFallback = () => {
    return(
      <ErrorBoundary fallbackRender={errorFallback} onError={myErrorHandler}>
        <Content  setSpinnerVisible={setSpinnerVisible} 
          spinnerVisible={spinnerVisible} darkMode={darkMode} hndPrice={hndPrice} toastError={toastError} lendly={network ? network.lendly["WEVE"] : null}/>
      </ErrorBoundary>
    )
  }

  return (
        <ErrorBoundary fallbackRender={errorFallback} onError={myErrorHandler}>
          {/* <NetworkError message='Please switch to Fantom Opera' show={showNetworkError}/> */}
          <Content setSpinnerVisible={setSpinnerVisible} 
            spinnerVisible={spinnerVisible} darkMode={darkMode} hndPrice={hndPrice} toastError={toastError} lendly={network ? network.lendly["WEVE"] : null}/>
        </ErrorBoundary>
       
  )
}

export default FantomWEVE;
